























// vue
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { I8Icon } from 'i8-ui';
import { PrPage } from '../../mixin';

// Icon library
import { faHandPointer } from '@fortawesome/pro-light-svg-icons/faHandPointer';
// Add all icons to the library
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faHandPointer);

export default Vue.extend({
  mixins: [PrPage],

  components: {
    I8Icon,
  },

  data() {
    return {
      string: {
        ['identitii.payreg.reportitem.v1.ReportItem']: 'transaction',
        ['identitii.payreg.report.v1.Report']: 'report',
      } as Record<string, string>,
      formModel: { file: {} } as { file: File },
    };
  },

  computed: {
    ...mapGetters('overlay', ['pluginContext']),

    isDocumentList(): boolean {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;
      return vm.pluginContext.type === 'document.list';
    },

    itemName(): string {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;
      const doctype = vm.pluginContext.apiQuery.type;
      return this.string[doctype] || 'item';
    },
  },
});
